@import './../../styles/config.scss';

.container {
  background-color: white;
  z-index: 100;

  .navbar {
    user-select: none;
    width: $screenWidth;
    padding: 0px 20px;
    height: $navbarHeight;

    .barsIcon {
      cursor: pointer;
    }
  }

  .items {
    padding: 0 20px;
    height: calc(100vh - $navbarHeight);

  }
}