.container {
  .title {
    background-color: #C8C6C4;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .h1 {
    font-size:20px;
    font-weight: bold;
  }
  .item {
    padding: 10px 20px;
  }

  .btn {
    border-radius:5px;
    padding:4px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #c2c2c2;
    color: #000;
    font-size: 12px;
  }
}